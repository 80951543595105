import React, { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Popup({ onClose, value, data, setPopup, logo }) {
  useEffect(() => {
    if (value) {
      document.getElementById("popup").style.visibility = "visible";
      document.getElementById("popup").style.opacity = "1";
    } else {
      document.getElementById("popup").style.opacity = "0";
      setTimeout(() => {
        document.getElementById("popup").style.visibility = "hidden";
      }, 500);
    }
  }, [value]);

  function slug(link) {
    if (link.startsWith("/")) {
      return link;
    } else {
      return `/${link}`;
    }
  }

  return (
    <div
      className={`fixed top-0 transition-opacity duration-700 ${
        value ? "visible" : "hidden"
      } bottom-0 left-0 right-0 z-[99]`}
      id="popup"
      role="button"
      onClick={onClose}
    >
      {/* <div className="absolute blur-xl top-0 left-0 right-0 bottom-0" /> */}
      <div className="flex h-full w-full justify-end items-end z-[100] px-2 pb-2">
        <div
          className="relative w-full sm:w-[550px] bg-white px-3 sm:px-5 py-4 border-[1px] border-solid border-[#993333]"
          onClick={(e) => e.stopPropagation()}
        >
          <span
            className="absolute z-50 right-3 top-2 p-1 cursor-pointer text-4xl text-black"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>

          <div className="text-center relative w-full flex flex-col-reverse sm:flex-row sm:space-x-2 ">
            <div className="relative sm:flex-[0.5]">
              <p className="font-primary leading-[29px] text-lg sm:text-[20px] font-semibold mb-3 text-black">
                {data?.title}
              </p>
              {data?.description && (
                <p className="text-xs sm:text-base leading-6 font-poppins text-black">
                  {data.description}
                </p>
              )}
              {data?.linkType === "slug" && (
                <div className="pt-3">
                  {" "}
                  <Link
                    className="fill-btn-sqr px-5 py-2 no-underline"
                    href={slug(data.slug.current)}
                    onClick={onClose}
                  >
                    <span className="button-text">{data.buttonText}</span>
                  </Link>
                </div>
              )}{" "}
              {data?.linkType === "url" && (
                <div className="pt-3">
                  <a
                    href={data.url}
                    onClick={onClose}
                    target="_blank"
                    rel="noreferrer"
                    className="fill-btn-sqr px-5 py-2 no-underline"
                  >
                    <span className="button-text">{data.buttonText}</span>
                  </a>
                </div>
              )}
              {data?.linkType === "subscribe" && (
                <div className="pt-3">
                  <button
                    onClick={() => setPopup(true)}
                    className="fill-btn-sqr px-5 py-2 no-underline"
                  >
                    <span className="button-text">{data.buttonText}</span>
                  </button>
                </div>
              )}
            </div>
            <div className="relative w-auto h-full max-h-[150px] sm:max-h-[230px] mb-4 sm:mb-0 sm:flex-[0.5]">
              <GatsbyImage
                image={data.image?.image.asset?.gatsbyImageData}
                loading="lazy"
                objectFit="contain"
                alt={data.image.alt}
                className="w-auto h-full max-h-[150px] sm:max-h-[230px]"
              />
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
