import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../common/sidebar";
import SidebarCart from "../../common/sidebar-cart";
// import useGlobalContext from "../../hooks/use-context";
import { GatsbyImage } from "gatsby-plugin-image";
import { CartContext } from "../../../lib/context/cartContext";
import NewletterModal from "../../common/NewletterModal";
import Modal from "./modal";
import NavMenu from "./navmenu";
import HeaderAdSpace from "./headerAd";

const Header = ({ reviveAds, headerAds, data, socialLinks, barEnabled }) => {
  const [openCart, setOpenCart] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [popup, openPopup] = useState(false);
  const [pdf, setPdf] = useState("");
  const { cart } = useContext(CartContext);

  // Sticky Menu Area start
  useEffect(() => {
    window.addEventListener("scroll", sticky);
    window.addEventListener("wheel", sticky);
    return () => {
      window.removeEventListener("scroll", sticky);
      window.removeEventListener("wheel", sticky);
    };
  });

  const sticky = (e) => {
    const header = document.querySelector(".header-main");
    const scrollTop = window.scrollY;
    scrollTop >= 40
      ? header.classList.add("sticky-header")
      : header.classList.remove("sticky-header");
  };
  // Sticky Menu Area End

  return (
    <>
      <div className={barEnabled && "mt-40"}>
        {/* <div className={`w-full h-28 mb-[20px] mt-[20px] my-ad`}>
          <div className="max-w-[728px] mx-auto w-full relative">
            <div
              className="w-full h-[90px]"
              dangerouslySetInnerHTML={{ __html: reviveAds.code }}
            ></div>
          </div>
        </div> */}
        {!!headerAds?.length && (
          <HeaderAdSpace
            data={headerAds}
            bottomPadding
            barEnabled={barEnabled}
          />
        )}
      </div>
      <header className="header1">
        <div
          id="header-sticky"
          className={`header-main header-main1 fixed flex justify-between w-full bg-white z-20 ${
            barEnabled
              ? !!headerAds?.length &&
                "announcement top-32 sm:top-36 md:top-[180px]"
              : !!headerAds?.length
              ? "top-24 md:top-[120px]"
              : "top-0"
          }`}
        >
          <div className="container container-big">
            <div className="row align-items-center">
              <div className="col-xl-12 col-lg-12">
                <div className="header-main-content-wrapper min-h-[80px]">
                  <div className="header-main-left header-main-left-header1">
                    <div className="header-logo header1-logo my-auto">
                      <Link
                        className="text-inherit no-underline logo-bl"
                        to="/"
                      >
                        <GatsbyImage
                          image={data.logo.image.asset.gatsbyImageData}
                          alt={data.logo.alt}
                          loading="eager"
                          objectFit="contain"
                          className="w-full h-auto xl:max-w-[200px]"
                        />
                      </Link>
                    </div>
                    <div className="main-menu main-menu1 d-none d-xl-block">
                      <nav id="mobile-menu" className="xl:pl-0 2xl:pl-5">
                        <NavMenu
                          menu={data.menuLinks}
                          setPdf={setPdf}
                          openPopup={openPopup}
                        />
                      </nav>
                    </div>
                  </div>
                  <div className="header-main-right header-main-right-header1">
                    <span
                      onClick={() => setOpenCart(true)}
                      className="action-btn cart-btn d-inline-flex action-item-cart"
                    >
                      <i className="fas fa-shopping-basket"></i>
                      {!!cart.length && (
                        <span className="product-quantity-circle-cart">
                          ({cart.length})
                        </span>
                      )}
                    </span>

                    <a
                      className="border-btn-rounded d-none d-lg-inline-flex no-underline cursor-pointer"
                      // onClick={() => setOpenModal(true)}
                      href="https://lp.constantcontactpages.com/sl/qrYkEUZ"
                      target="_blank"
                    >
                      {data.ctaButtonText}
                    </a>

                    <div className="menu-bar d-xl-none">
                      <span
                        className="side-toggle"
                        onClick={() => setShowSidebar(true)}
                      >
                        <div className="bar-icon">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {popup && <Modal pdf={pdf} openPopup={openPopup} />}
      </header>

      {/* sidebar start */}
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        menu={data.menuLinks}
        logo={data.logo}
        socialLinks={socialLinks}
      />

      {/* side cart */}
      <SidebarCart openCart={openCart} setOpenCart={setOpenCart} />
      {/* side cart */}

      {openModal && <NewletterModal closeModal={() => setOpenModal(false)} />}
    </>
  );
};

export default Header;
