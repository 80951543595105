import React from "react";
import "bootstrap/dist/js/bootstrap";
import "./src/styles/tailwind.css";
import "./src/styles/global.scss";
import Layout from "./src/components/layout";
import ShopProvider from "./src/lib/context/cartContext";
import { ApolloProvider } from "@apollo/client";
import client from "./src/apollo/client";

export const wrapPageElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ShopProvider>
        <Layout>{element}</Layout>
      </ShopProvider>
    </ApolloProvider>
  );
};
