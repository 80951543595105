import { Link } from "gatsby";
import React, { useContext } from "react";
import { CartContext } from "../../lib/context/cartContext";

const SidebarCart = ({ openCart, setOpenCart }) => {
  const {
    cart,
    estimatedCost,
    checkoutUrl,
    removeFromCart,
    updateQuantityCart,
    disabled,
  } = useContext(CartContext);

  function handleUpdateCart(product) {
    updateQuantityCart(product);
  }
  return (
    <>
      <div className="fix">
        <div
          className={`sidebar-action sidebar-cart ${
            openCart ? "cart-open" : ""
          }`}
        >
          <button className="close-sidebar" onClick={() => setOpenCart(false)}>
            Close<i className="fal fa-times"></i>
          </button>
          <h4 className="sidebar-action-title">Shopping Cart</h4>
          {cart.length === 0 && <h5>Your cart is empty</h5>}

          {cart.length >= 1 && (
            <>
              <div className="sidebar-action-list">
                {cart.map((item, index) => (
                  <div className="sidebar-list-item" key={index}>
                    <div className="product-image pos-rel">
                      <Link
                        className="no-underline"
                        to={`/products/${item.handle}`}
                      >
                        <img
                          src={item.image}
                          alt={item.title}
                          className="aspect-[5/6]"
                        />
                      </Link>
                    </div>
                    <div className="product-desc">
                      <div className="product-name">
                        <Link
                          className="no-underline text-[#993333] hover:text-[#993333] transition-all ease-linear duration-200 font-semibold text-base uppercase"
                          to={`/products/${item.handle}`}
                        >
                          {item.title}
                        </Link>
                      </div>
                      <div className="product-pricing mt-2">
                        <div className="flex items-center justify-between w-20 space-x-2 mb-3 sm:mb-0">
                          <button
                            className="p-0 rounded-full border-[1px] border-solid border-[#993333] hover:bg-secondary hover:text-white"
                            disabled={disabled}
                            onClick={() =>
                              handleUpdateCart({
                                ...item,
                                quantity: item.quantity - 1,
                              })
                            }
                            aria-label="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 12H6"
                              />
                            </svg>
                          </button>
                          <span>{item?.quantity}</span>
                          <button
                            disabled={disabled}
                            className="p-0 rounded-full border-[1px] border-solid border-[#993333] hover:bg-secondary hover:text-white"
                            onClick={() =>
                              handleUpdateCart({
                                ...item,
                                quantity: item.quantity + 1,
                              })
                            }
                            aria-label="button"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v12m6-6H6"
                              />
                            </svg>
                          </button>
                        </div>
                        <span className="price-now">$ {item.price}</span>
                      </div>

                      <button
                        className="remove-item"
                        onClick={() => removeFromCart(item)}
                        aria-label="button"
                      >
                        <i className="fal fa-times"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="product-price-total">
                <span>Subtotal :</span>
                <span className="subtotal-price">
                  ${parseFloat(estimatedCost.subtotalAmount.amount)}
                </span>
              </div>
              <div className="sidebar-action-btn">
                {/* <Link className="fill-btn no-underline" to="/cart">
                  View cart
                </Link> */}
                <a href={checkoutUrl} className="border-btn no-underline">
                  Checkout
                </a>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        onClick={() => setOpenCart(false)}
        className={`offcanvas-overlay ${openCart ? "overlay-open" : ""}`}
      ></div>
    </>
  );
};

export default SidebarCart;
