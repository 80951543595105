import { Link } from "gatsby";
import React from "react";
import { PortableText } from "@portabletext/react";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomLink from "../../common/link";

const Footer = ({ footer, cc, socialLinks = [] }) => {
  return (
    <footer className="footer1-bg">
      <section className="footer-area footer-area1 footer-area1-bg pt-95 pb-20">
        <div className="footer-bg-shape">
          <img src="/assets/img/shape/pattern.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer1-widget footer1-widget1 mb-40">
                <div className="footer-widget-title">
                  <h4>{footer.about.title}</h4>
                </div>
                <p className="mb-35">{footer.about.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer1-widget footer1-widget2 mb-40">
                <div className="footer-widget-title">
                  <h4>{footer.pagesLinks.title}</h4>
                </div>
                <ul className="pl-0">
                  {footer.pagesLinks?.pagesLinks.map((footerLink, index) => (
                    <li key={index}>
                      <CustomLink
                        data={footerLink}
                        classNames="text-inherit no-underline"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-widget footer1-widget footer1-widget4 mb-40 ">
                <div className="footer-widget-title">
                  <h4>{footer.getInTouch.title}</h4>
                </div>
                <div className="footer-contact">
                  <ul className="pl-0">
                    <li>
                      <div className="single-contact">
                        <div className="contact-icon">
                          <GatsbyImage
                            image={
                              footer.getInTouch.phone.icon.asset.gatsbyImageData
                            }
                            alt={footer.getInTouch.phone.text}
                            loading="lazy"
                            objectFit="contain"
                            className="w-5 h-5"
                          />
                        </div>
                        <p>
                          <a
                            className="text-inherit no-underline"
                            href={footer.getInTouch.phone.url}
                          >
                            {footer.getInTouch.phone.text}
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="single-contact">
                        <div className="contact-icon">
                          <GatsbyImage
                            image={
                              footer.getInTouch.email.icon.asset.gatsbyImageData
                            }
                            alt={footer.getInTouch.email.text}
                            loading="lazy"
                            objectFit="contain"
                            className="w-5 h-5"
                          />
                        </div>
                        <p>
                          <a
                            className="text-inherit no-underline"
                            href={footer.getInTouch.email.url}
                          >
                            {footer.getInTouch.email.text}
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="single-contact">
                        <div className="contact-icon">
                          <GatsbyImage
                            image={
                              footer.getInTouch.address.icon.asset
                                .gatsbyImageData
                            }
                            alt={footer.getInTouch.address.text}
                            loading="lazy"
                            objectFit="contain"
                            className="w-5 h-5"
                          />
                        </div>
                        <p>
                          <span className="text-inherit no-underline cursor-pointer">
                            {footer.getInTouch.address.text}
                          </span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                {socialLinks.length > 0 && (
                  <div className="footer-social">
                    {/* <span>Connect:</span> */}
                    <div className="social-links">
                      <ul className="pl-0">
                        {socialLinks.map((socialLink, index) => (
                          <li key={index}>
                            <a
                              href={socialLink.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <GatsbyImage
                                image={socialLink.icon.asset.gatsbyImageData}
                                title={socialLink.text}
                                alt={socialLink.text}
                                objectFit="contain"
                                className="w-8 h-8 object-contain"
                                loading="lazy"
                              />
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright1-area">
          <div className="container">
            <div className="copyright1-inner">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-4">
                  <div className="footer-logo">
                    <Link className="text-inherit no-underline" to="/">
                      <GatsbyImage
                        image={footer.footerLogo.image.asset.gatsbyImageData}
                        alt={footer.footerLogo.alt}
                        loading="lazy"
                        objectFit="contain"
                        className="w-full h-full"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="copyright-text copyright1-text">
                    {cc && <PortableText value={cc} />}
                  </div>
                </div>
                <div className="col-lg-3 col-md-2">
                  <div className="go-top-btn">
                    <a className="go-top" href="#" aria-label="link">
                      <i className="fal fa-long-arrow-up"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
