import React, { createContext, useState, useEffect } from "react";
import { addItem, createCart, getCart, removeItem, updateCart } from "../cart";

export const CART_ID = "chickenwhisperer-cart";

const cartDefault = {
  cart: [],
  setCart: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  updateQuantityCart: () => {},
  cartOpen: false,
  setCartOpen: () => {},
  cartId: "",
  setCartId: () => "",
  estimatedCost: null,
  setEstimatedCost: () => {},
  checkoutUrl: "",
  setCheckoutUrl: () => "",
  disabled: false,
  setDisabled: () => {},
};
const CartContext = createContext(cartDefault);

const ShopProvider = ({ children }) => {
  let [cart, setCart] = useState([]);
  let [disabled, setDisabled] = useState(false);
  let [cartOpen, setCartOpen] = useState(false);
  let [cartId, setCartId] = useState("");
  let [estimatedCost, setEstimatedCost] = useState(null);
  let [checkoutUrl, setCheckoutUrl] = useState("");

  useEffect(() => {
    if (localStorage.getItem(CART_ID)) {
      const acreageCartId = JSON.parse(localStorage.getItem(CART_ID));

      if (acreageCartId) {
        setCartId(acreageCartId);
        getCartData(acreageCartId);
      }
    }
  }, [cartId]);

  // ============================================================================================= //
  // ======================================= GET CART DATA ======================================= //
  // ============================================================================================= //

  const getCartData = async (id) => {
    let newCart = [];
    const response = await getCart(id);
    if (response?.id) {
      setCheckoutUrl(response.checkoutUrl);
      setEstimatedCost(response.cost);
      response.lines?.nodes.map((item) =>
        newCart.push({
          shopifyId: item.merchandise.id,
          quantity: item.quantity,
          lineId: item.id,
          title: item.merchandise.title,
          handle: item.merchandise.product.handle,
          productId: item.merchandise.product.id,
          price: item.merchandise.price.amount,
          image: item.merchandise.image.url,
        })
      );

      setCart(newCart);
      setCartId(response.id);

      localStorage.setItem(CART_ID, JSON.stringify(response.id));
    } else {
      localStorage.removeItem(CART_ID);
    }
  };

  // ============================================================================================= //
  // ===================================== ADD ITEM TO CART ====================================== //
  // ============================================================================================= //

  const addToCart = async (product) => {
    setCartOpen(true);
    setDisabled(true);
    try {
      // WHEN CART IS EMPTY
      if (cart.length === 0) {
        let cartResponse;

        cartResponse = await createCart(product);

        setCheckoutUrl(cartResponse.checkoutUrl);
        setEstimatedCost(cartResponse.cost);
        setCartId(cartResponse.id);

        setCart([
          {
            ...product,
            lineId: cartResponse.lines?.nodes[0].id,
            productId: product.productId,
          },
        ]);
        localStorage.setItem(CART_ID, JSON.stringify(cartResponse.id));
      } else {
        // WHEN CART IS NOT EMPTY
        const acreageCartId = JSON.parse(localStorage.getItem(CART_ID));
        let cartResponse;

        cartResponse = await addItem(acreageCartId, {
          quantity: product.quantity,
          merchandiseId: product.shopifyId,
          sellingPlanId: product.sellingPlanId,
        });
        setCheckoutUrl(cartResponse.checkoutUrl);
        setEstimatedCost(cartResponse.cost);
        getCartData(acreageCartId);
      }
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  };

  // ============================================================================================= //
  // ===================================== UPDATE ITEM IN CART =================================== //
  // ============================================================================================= //

  const updateQuantityCart = async (product) => {
    const acreageCartId = JSON.parse(localStorage.getItem(CART_ID));

    setDisabled(true);
    let newCart = [];
    try {
      const cartResponse = await updateCart(acreageCartId, product);
      setCheckoutUrl(cartResponse.checkoutUrl);
      setEstimatedCost(cartResponse.cost);

      cartResponse.lines?.nodes.map((item) => {
        let added = false;
        cart.map((cartItem) => {
          if (
            cartItem.shopifyId === item.merchandise.id &&
            cartItem.sellingPlanId ===
              item.sellingPlanAllocation?.sellingPlan.id
          ) {
            added = true;

            return newCart.push({
              ...cartItem,
              quantity: item.quantity,
              lineId: item.id,
              price: cartItem.sellingPlanId
                ? item.sellingPlanAllocation?.priceAdjustments[0].price.amount
                : cartItem.price,
            });
          }
        });
      });

      setCart(newCart);

      localStorage.setItem(CART_ID, JSON.stringify(cartResponse.id));
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  };

  // ============================================================================================= //
  // ==================================== REMOVE ITEM FROM CART ================================== //
  // ============================================================================================= //

  const removeFromCart = async (product) => {
    const acreageCartId = JSON.parse(localStorage.getItem(CART_ID));

    await removeItem(acreageCartId, product);
    if (acreageCartId) {
      setCartId(acreageCartId);
      getCartData(acreageCartId);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartOpen,
        addToCart,
        checkoutUrl,
        removeFromCart,
        updateQuantityCart,
        estimatedCost,
        cartId,
        disabled,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const ShopConsumer = CartContext.Consumer;
export default ShopProvider;
export { ShopConsumer, CartContext };
