import React, { useState } from "react";

const Modal = ({ pdf, openPopup }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    fetch("/.netlify/functions/mediakit", {
      method: "POST",
      headers: {
        Accept: "application.json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, state }),
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        console.log(e);
        alert("There is something went wrong! Please try again");
        setLoading(false);
      });
  }

  return (
    <div
      className="fixed top-0 bottom-0 right-0 left-0 w-full h-full bg-black/20 z-[999]"
      onClick={() => openPopup(false)}
    >
      <div className="relative w-full h-full flex items-center justify-center">
        <div
          className="relative max-w-2xl w-full h-auto py-10 px-5 bg-white border border-solid border-black rounded-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="absolute top-1 right-1 text-black outline-none p-1"
            onClick={() => openPopup(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
          {!success && (
            <form onSubmit={handleSubmit} className="space-y-2 px-3">
              <h2 className="font-semibold text-2xl">
                Thank you for your interest in Chicken Whisperer Magazine
                advertising opportunities.
              </h2>
              <p className="text-base">
                Please provide your name, email and state to view the media kit.
              </p>
              <input
                type="name"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full rounded-md h-10 border border-solid border-black focus:outline-none outline-none px-2 py-1 mb-2"
                placeholder="Enter your name"
              />
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full rounded-md h-10 border border-solid border-black focus:outline-none outline-none px-2 py-1 mb-2"
                placeholder="Enter your email"
              />
              <input
                type="text"
                name="state"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                className="w-full rounded-md h-10 border border-solid border-black focus:outline-none outline-none px-2 py-1 mb-2"
                placeholder="Enter your state"
              />
              <button
                type="submit"
                disabled={loading}
                className="bg-[#993333] w-40 h-10 text-white rounded-lg"
              >
                Submit
              </button>
            </form>
          )}
          {success && (
            <div className="px-6 py-6 border-0 rounded relative mb-4 mt-4">
              <h2 className="font-semibold text-2xl mb-2">Thank You!</h2>
              <div className="pt-2">
                <a
                  href={pdf}
                  target="_blank"
                  download
                  className="bg-[#993333] text-white rounded-lg px-4 py-[10px] no-underline"
                >
                  Download Media Kit
                </a>
              </div>
            </div>
          )}
          {error && (
            <div className="text-white px-6 py-2 border-0 rounded relative mb-4 bg-red-600 mt-4">
              <span className="text-xl inline-block mr-5 align-middle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              </span>
              <span className="inline-block align-middle mr-8">{error}</span>
              <button
                onClick={() => setError("")}
                className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-6 outline-none focus:outline-none"
              >
                <span>×</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
