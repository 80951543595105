exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-contest-jsx": () => import("./../../../src/pages/contest.jsx" /* webpackChunkName: "component---src-pages-contest-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-magazines-jsx": () => import("./../../../src/pages/magazines.jsx" /* webpackChunkName: "component---src-pages-magazines-jsx" */),
  "component---src-pages-mediakit-jsx": () => import("./../../../src/pages/mediakit.jsx" /* webpackChunkName: "component---src-pages-mediakit-jsx" */),
  "component---src-pages-reader-survey-jsx": () => import("./../../../src/pages/reader-survey.jsx" /* webpackChunkName: "component---src-pages-reader-survey-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-store-jsx": () => import("./../../../src/pages/store.jsx" /* webpackChunkName: "component---src-pages-store-jsx" */),
  "component---src-pages-subscribe-jsx": () => import("./../../../src/pages/subscribe.jsx" /* webpackChunkName: "component---src-pages-subscribe-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-template-author-jsx": () => import("./../../../src/template/author.jsx" /* webpackChunkName: "component---src-template-author-jsx" */),
  "component---src-template-blog-jsx": () => import("./../../../src/template/blog.jsx" /* webpackChunkName: "component---src-template-blog-jsx" */),
  "component---src-template-category-jsx": () => import("./../../../src/template/category.jsx" /* webpackChunkName: "component---src-template-category-jsx" */),
  "component---src-template-product-jsx": () => import("./../../../src/template/product.jsx" /* webpackChunkName: "component---src-template-product-jsx" */),
  "component---src-template-tags-jsx": () => import("./../../../src/template/tags.jsx" /* webpackChunkName: "component---src-template-tags-jsx" */)
}

