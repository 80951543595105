import React, { useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

const CookiesBanner = () => {
  const pagelocation = useLocation();
  const [show, setShow] = useState(true);

  const available = Boolean(Cookies.get("chickenwhisperer-gdpr-cookies"));

  if (available || !show) return null;

  function acceptCookies() {
    Cookies.set("chickenwhisperer-gdpr-cookies", "true");
    initializeAndTrack(pagelocation);
    setShow(false);
  }

  function declineCookies() {
    Cookies.set("chickenwhisperer-gdpr-cookies", "false");
    setShow(false);
  }

  return (
    <CookieConsent disableStyles hideOnAccept hideOnDecline>
      <div className="w-full fixed bottom-0 left-0 z-50">
        <div className="w-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:items-center space-x-3 border border-solid border-[#555555] px-3 py-3 sm:px-10 lg:px-20 bg-black/90 text-white font-lora">
          <p className="text-sm mb-0">
            On our websites, we employ cookies to bolster technical
            functionalities, enriching your user experience and helping us
            refine our site. Your continued use of this website implies
            acceptance of our Privacy Policy and Terms of Use.
          </p>
          <div className="space-x-2 flex">
            <button
              className="px-4 py-1 leading-4 text-sm h-7 border border-solid border-white rounded-full w-fit hover:bg-white hover:text-black hover:border-black"
              onClick={acceptCookies}
            >
              Accept
            </button>
            <button
              className="px-4 py-1 leading-4 text-sm h-7 border border-solid border-white rounded-full w-fit hover:bg-white hover:text-black hover:border-black"
              onClick={declineCookies}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </CookieConsent>
  );
};

export default CookiesBanner;
